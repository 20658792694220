import MainPanel from "../Components/Parts/MainPanel.js";
import OrangeButton from "../Components/Parts/OrangeButton.js";


function Contact () {

    const top = [
        {
            title:'お問合せ',
            text:'カチッとページに関するご質問などございましたらお気軽にお問い合わせください。GoogleFormの入力でのお問い合わせに加えて、InstagramやTwitterからのお問い合わせも可能です。',
            img:'chat.png',
        }
    ]

    const orange = [
        {
            link:'https://instagram.com/cachitto_page',
            text:'instagram'
        },
        {
            link:'https://twitter.com/cachitto_page',
            text:'Twitter'
        },
        {
            link:'https://lin.ee/G2W4JgW',
            text:'Line'
        },

        


    ]
    
    return (
        <div className="bg-gray-100">
            {top && top.map ((content)=> (
            <MainPanel
                 title={content.title}
                 text={content.text}
                 img={content.img}
            
            
            />

            ))}

        <div className="pb-10">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScHSCsN7DDH5aeVM6afqAYsU9qT0caAln_mT0VZX-CK694nGA/viewform?embedded=true" width="100%" height="640px" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
        </div>
        

        <div className="text-center"> 
            {orange && orange.map ((content)=> (
            <OrangeButton
                 link={content.link}
                 text={content.text}
                 
            
            
            />


            ))}


        </div>




        </div>
    );
}

export default Contact;