import MainPanel from "../Components/Parts/MainPanel.js";
import PriceListPanel from "../Components/Parts/PriceListPanel.js";
import Points from "../Components/Parts/PointsPanel.js";


import {FaHandshake,FaPager,FaYenSign,FaGooglePlusSquare} from "react-icons/fa";
import {MdAnimation,MdOutlinePhotoAlbum,MdContentCopy} from 'react-icons/md'
import {AiFillPicture} from "react-icons/ai";

function EventPage () {
    const top = [
        {
            title:"短期掲載用のホームページ制作ならカチッとmini",
            text:"カチッとページではイベント企画時や採用時に必要な短期間掲載のホームページも制作しております。掲載期間は最短3ヶ月からご用意いたします。",
            img:"event.png"
        }
    ]

    const priceList = [
        {
            icon:<FaYenSign className="m-auto"/>,
            text:'3ヶ月 ¥33,000(税込)'
        },
        {
            icon:<MdContentCopy className="m-auto"/>,
            text:'セクション数:5つまで'
        },
        {
            icon:<FaPager className="m-auto"/>,
            text:'ページ数:1ページまで'
        },
        {
            icon:<AiFillPicture className="m-auto"/>,
            text:'デザイン:テンプレート'
        },

    ]

    const points = [
        {
            title:"契約期間は最短3ヶ月！",
            text:'短期契約のため、イベント告知から開催時までの必要な時に必要な分だけの契約が可能です。',
            img:'calender.png'
        },
        {
          title:"テンプレートを使用し、納品までをスピーディーに！",
          text:'デザインのカスタマイズ性はなくなりますが、その分完成までのスピード感を大切にしています。もちろん、イベントのイメージに沿ったカラーやフォントの対応は可能です。',
          img:'design.png'
      },
  
  
  
  
      ]
  
  
  


    return (
        <div>
            {top && top.map ((content)=> (
            <MainPanel
                 title={content.title}
                 text={content.text}
                 img={content.img}
            
            
            />

            ))}

          <div className='bg-white'>
                 <h2 className='w-4/5 m-auto text-center text-xl py-3 font-bold'>イベント企画時に、<span className="inline-block">こんな悩みはございませんか？</span></h2>
                 <img className='w-1/2 md:w-1/3 m-auto' src="./assets/conversation.png" alt="" />

             <div className='w-11/12 m-auto text-center'>
                <p className='py-2'>1 イベントの告知などでHPが必要だが<span className='inline-block'>契約期間が長くて割に合わない</span> </p>
                <p className='py-2'>2 イベント企画からHP開設までのスピード感を大切にしたい</p>
            </div>

             </div>
            


        <div className="bg-LightGray">
            <h2 className="text-center font-bold text-xl py-5">カチッとminiなら！</h2>
        {points && points.map ((content)=> (
            <Points
                 title={content.title}
                 text={content.text}
                 img={content.img}
            
            
            />

            ))}
        </div>

        <div className="py-5">
        <h2 className="text-center text-xl font-bold py-5">カチッとminiの料金体系</h2>

        <div className=" md:columns-2 md:w-2/3 m-auto">
        {priceList && priceList.map ((content)=> (
        <PriceListPanel
             icon={content.icon}
             text={content.text}
             attention={content.attention}
        />
        ))}

        </div>
        </div>
        
        </div>
    );
}

export default EventPage;