import OrangeButton from './Parts/OrangeButton.js';

const orange = [
    {
        link:"https://cachitto-biz.com/",
        text:"詳しくはこちらから！",
    }
]
function Biz () {
    return (

        
        <div className="py-5 text-center md:flex">
            <div className='md:w-1/2'>
            <img className="" src="./assets/biz.png" alt="" />
            </div>

            <div className='md:w-1/2 md:my-auto'>

                <div className='w-4/5 m-auto text-left py-5 leading-loose'>
                    <p className=''>カチッとページでは、WEB制作の他にグラフィックデザインの制作も行っています。例えば、ビジネス導入期に必要となる「名刺」「チラシ」「メニュー表」こうした宣伝広告について、グラフィックデザインの作成を承っております。WEB制作サービスと同様に、情報をわかりやすく伝える、シンプルなデザインに仕上げます。</p>
                    <p className=''>また、HP制作の際に、料金の一括でのお支払いをご希望のお客様や、デザインに関しても細かく打ち合わせをしたいという方は、以下ページもご参考くださいませ。</p>
                </div>
            {orange && orange.map ((content)=> (
                     <OrangeButton
                         link={content.link}
                         text={content.text}
                         />

                     ))}
            </div>

        </div>



    )
};

export default Biz;