import MainPanel from "../Components/Parts/MainPanel.js";
import FaqPanel from "../Components/Parts/FaqPanel.js";

function FAQ () {
    const top = [
        {
            title:"よくある質問",
            text:"お問合せフォームで確認前にこちらを参照ください。もしかすると、質問事項を見つけることができ、お客様のお時間を無駄にしないお手伝いができるかもしれません。",
            img:"faq.png"
        }
    ]

    const faq = [
        {
            title:'スマホから注文可能ですか',
            text:'可能です！スマートフォンからでも簡単にカチッとしたページの作成をご依頼いただけます。ただし、完成後にPC画面での見栄え等の確認をしていただきたいので、場合によってはPC環境はある方が良いかもしれません。',
        },

        {
            title:'クレジットカードでの支払いは可能ですか',
            text:'可能です！stripe を利用し、簡潔に決済を完了致します。また一度支払いのご登録をいただくと、次月以降のお支払は自動決済となります。',
        },
        {
            title:'本当に月額¥5,500で制作依頼できますか',
            text:'はい！本当に毎月¥5,500だけでご依頼いただけるプランをご用意しております。ただし、下層ページなどが多くなる場合などはトッピングでの対応としております。また、月額の保守料金以外料金は発生致しません。もちろん初期費用も一切かかりません。サーバー料やドメイン料も月々の料金内に含まれています。',
        },
        {
            title:'かっこいい写真などは必要ですよね',
            text:'可必要ありません！商用利用可能なフリー素材サイトから、お客様にご依頼いただいたHPにふさわしい素材を使用します。もちろん、お客様がすでにお持ちの素晴らしい素材なども使用可能です。',
        },
        {
            title:'納品後に内容の修正は可能ですか',
            text:'可能です！微細な修正は月々の料金内で可能です！微細な修正とは、画像の差し替えや文章の修正等です。万が一大規模な修正が必要な場合はご相談ください。別途対応可能です。ただし別料金が発生する可能性がございます。',
        },



    ]
    return (
        <div>
            {top && top.map ((content)=> (
            <MainPanel
                 title={content.title}
                 text={content.text}
                 img={content.img}
            
            
            />

            ))}
            

            <div className="bg-gray-100 md:columns-1">
            {faq && faq.map ((content)=> (
            <FaqPanel
                 title={content.title}
                 text={content.text}
            
            
            />

            ))}
            

            </div>
        </div>
    );
}

export default FAQ;