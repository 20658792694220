
import MainPanel from "../Components/Parts/MainPanel.js";
import PriceOptionPanel from "../Components/Parts/PriceOptionPanel.js";


import {FaHandshake,FaPager,FaYenSign,FaGooglePlusSquare} from "react-icons/fa";
import {MdAnimation,MdOutlinePhotoAlbum,MdContentCopy} from 'react-icons/md'
import {AiFillPicture} from "react-icons/ai";

import PriceListPanel from "../Components/Parts/PriceListPanel.js";

function Price () {
    const top = [
        {
            title:"シンプルな料金プラン",
            text:'カチッとページの料金プランは１プランのトッピング方式。見積もりを取らずして、大まかな予算感をイメージしていただけるよう設計しております。',
            img:'price.png'
        },
        
    ]

    const priceList = [
        {
            icon:<FaYenSign className="m-auto"/>,
            text:'月額 5,500円(税込)'
        },
        {
            icon:<MdContentCopy className="m-auto"/>,
            text:'セクション数:4つまで'
        },
        {
            icon:<FaPager className="m-auto"/>,
            text:'ページ数:1ページまで'
        },
        {
            icon:<AiFillPicture className="m-auto"/>,
            text:'デザイン:テンプレート'
        },

    ]

    const priceOption =[
        {
            icon:<FaPager className="m-auto" />,
            title:'ページ数の追加',   
            price:'1ページ:¥3,300',
            attention:'セクション数は4つまでです。(共通部分は除きます)。',
        },
        {
            icon:<MdContentCopy className="m-auto" />,
            title:'セクション数の追加',   
            price:'1セクション:¥1,100',
            attention:'1ページを増やすまでは無いが、5つ以上セクションを用意したい場合にご検討ください。',
        },

        {
            icon:<MdAnimation className="m-auto" />,
            title:'アニメーションの追加',   
            price:'1種類:¥550',
            attention:'数の制限は特にございませんが多すぎる場合はお断りさせていただく場合がございます。',
        },
        {
            icon:<MdOutlinePhotoAlbum className="m-auto"/>,
            title:'スライダーの追加',   
            price:'1箇所:¥550',
            attention:'数の制限は特にございませんが多すぎる場合はお断りさせていただく場合がございます。',
        },
        {
            icon:<FaGooglePlusSquare className="m-auto" />,
            title:'Google My Businnesの管理',   
            price:'¥4,400',
            attention:'GoogleMyBusinnesの管理と簡単な運用を行います。',
        },

        {
            icon:<AiFillPicture className="m-auto"/>,
            title:'オリジナルデザイン',   
            price:'1サイト:¥5,500~',
            attention:'希望のデザインございましたら、一度ご相談ください。上記の価格を目安にデザインさせていただきます。ただしサービスの特性上、派手なデザインなどはできかねます。',
        },


        {
            icon:<FaHandshake className="m-auto" />,
            title:'パートナーシップ割引き',   
            price:'1ページ:¥2,200',
            attention:'各ページに以下のセクションを追加することで、月額料金をディスカウントさせていただきます。',
        },

    ]

    return (
        <div className=''>
        {top && top.map ((content)=> (
        <MainPanel
             title={content.title}
             text={content.text}
             img={content.img}
        
        
        />
        ))}

        <div>
            <img className="w-2/3 md:w-1/4 m-auto" src="./assets/shopping.png" alt="" />
        </div>

        <main className="w-4/5 m-auto">
            <h2 className="text-center"><span className="font-bold text-xl">Basic Plan</span> <br />基本プラン </h2>


        <div className=" md:columns-2  ">

        {priceList && priceList.map ((content)=> (
        <PriceListPanel
             icon={content.icon}
             text={content.text}
             attention={content.attention}
        />
        ))}

        </div>


        </main>

        <div className="bg-gray-500 text-gray-300 py-5">
        <h2 className="text-center"><span className="font-bold text-xl">Options</span> <br />主な追加オプション</h2>
        
        {priceOption && priceOption.map ((content)=> (
        <PriceOptionPanel
             icon={content.icon}
             title={content.title}
             price={content.price}
             limit1={content.limit1}
             limit2={content.limit2}
             limit3={content.limit3}
             attention={content.attention}
        />
        ))}

        </div>
      </div>
);
}

export default Price;