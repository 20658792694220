import OrangeButton from './OrangeButton.js';
import WhiteButton from './WhiteButton.js';

import { Helmet } from 'react-helmet';


function MainPanel (props) {

    const orange = [
        {
            link:'https://cachitto-page.com/price',
            text:'シンプルな料金プランはこちら'
        }
    ]

    const white = [
        {
            link:'https://cachitto-page.com/sample',
            text:'カチッとした制作実績はこちら'
        }
    ]




    return (
        <div>
            <Helmet>
                <title>{props.title} |カチッとページ</title>
                <meta name='description' content={props.text} />
            </Helmet>
        <div className="bg-LightOrange">
            <div className="w-4/5 m-auto">
               <h1 className="pt-40 md:text-3xl text-2xl font-bold">{props.title}</h1>
               <p className='md:w-2/3 pt-4'>{props.text}</p>

               <div className='md:flex'>
               <div className='py-10'>
                  <div className=''>
                   
                     {orange && orange.map ((content)=> (
                     <OrangeButton
                         link={content.link}
                         text={content.text}
                         />

                     ))}
                   </div>

                   <div className=''>
                   
                   {white && white.map ((content)=> (
                    <WhiteButton
                       link={content.link}
                       text={content.text}
                       />

                   ))}
                 </div>


               </div>
             

               <img className='md:w-1/2 md:pl-40' src={"./assets/"+ props.img} alt="" />

               </div>

            </div>
        </div>
        



        </div>
    );
}

export default MainPanel;