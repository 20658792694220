import MainPanel from "../Components/Parts/MainPanel.js";
import SamplePanel from "../Components/Parts/SamplePanel.js";

function Sample () {
    const top =[
        {
            title:'カチッとした制作実績',
            text:'これまでのカチッとページの制作実績並びに、パートナー様の紹介です。制作にあたって、この会社のようなHPを作ってほしい！などの注文方法も可能ですので、ぜひ一度ご覧くださいませ。',
            img:'sample.png',
        }
    ]

    const sample =[

        {
            link:'https://nobuhiro-kato.com/',
            twittercard:'NobuhiroKatoTwittercard.jpg',
        },

        {
            link:'https://coda-lunga.com/',
            twittercard:'CodalungaTwittercard.jpg',
        },



        {
            link:'https://stage-kyoto.com/doctor',
            twittercard:'StageTwittercard.jpg',
        },
        {
            link:'https://suzuna-kyoto-matsubara.com/',
            twittercard:'SuzunaTwittercard.jpg',
        },
        {
            link:'https://ryukoku-wp.net/',
            twittercard:'RyukokuWpTwittercard.jpg',
        },
        {
            link:'https://karaage-kickoff.com/',
            twittercard:'KickoffTwittercard.jpg',
        },






    ]

    return (
        <main>
            <div>
            {top && top.map ((content)=> (
            <MainPanel
                 title={content.title}
                 text={content.text}
                 img={content.img}
            
            
            />

            ))}
            </div>

            <div className="bg-gray-100">
            <div className="md:columns-3 md:grid-cols-2 w-4/5 m-auto  ">
            {sample && sample.map ((content)=> (
            <SamplePanel
                 link={content.link}
                 twittercard={content.twittercard}
            
            
            />

            ))}
            </div>


            </div>




        </main>
    );
}

export default Sample;