import OrangeButton from "./Parts/OrangeButton";


function EventPage () {

    const orange =[
        {
            link:'https://cachitto-page.com/mini',
            text:'詳細はこちら'
        },

    ]

    return (
        <div className="bg-LightOrange py-5">

            <h2 className="text-center text-xl font-bold">短期掲載のホームページ制作もお任せ下さい！</h2>

            <div className="md:flex">
            <div className='md:w-1/2'>
            <img className="" src="./assets/event.png" alt="" />
            </div>

            <div className="w-4/5 m-auto py-5 leading-loose ">
                <p className="">「イベント開催に伴い3ヶ月だけHPが欲しい...だけど、ほとんどのホームページ制作会社は一年以上の契約で無駄が生じてしまう...」なんてお悩みはございいませんか？</p>
                <p>カチッとページなら既存のプランとは別に、短期掲載用のホームページ制作プランをご用意しております。</p>

                <div className="text-center">
              {orange && orange.map ((content)=> (
                     <OrangeButton
                         link={content.link}
                         text={content.text}
                         />

                     ))}
            </div>

            </div>


            </div>



        </div>
    ); 
}

export default EventPage ;