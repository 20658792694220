import NaviLinks from "./NaviLinks";
import{ BiMenuAltLeft } from "react-icons/bi";
import{BiWindowClose} from "react-icons/bi";
import {useState} from "react";
function MobileNavigation() {
    
    const [open,setOpen]= useState (false);

    const hamburgerIcon =
    
    <BiMenuAltLeft 
    size="60px" color="#000000" 
    onClick={()=> setOpen(!open)}
    />

    const closeIcon =
    <BiWindowClose
    size="60px" color="#000000"
    onClick={()=> setOpen(!open)}
    />

    const closeMobileMenu = () => setOpen(false);


    return (
        
        <nav className="md:hidden bg-BrandOrange w-full absolute text-2xl hover:text-black leading-loose">
            <div className="w-11/12 m-auto">
                {open ? closeIcon : hamburgerIcon}
                {open && <NaviLinks isMobile={true} closeMobileMenu={closeMobileMenu} />}

            </div>
            
        </nav>
            
    );
}

export default MobileNavigation;