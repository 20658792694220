import { Routes, Route,} from 'react-router-dom';
import Home from './Pages/Home';
import Price from './Pages/Price';
import Sample from './Pages/Sample';
import Contact from './Pages/Contact';
import FAQ from './Pages/FAQ';
import EventPage from './Pages/EventPage';
import Blog from './Pages/Blog';



import NaviBar from './Components/NaviBar';
import Ad from './Components/Ad';
import Event from './Components/Event';
import Biz from './Components/Biz';
import Footer from './Components/Footer';

import Restaurant from './Template/Restaurant';



function App() {
  return (
    <div className='font-mono'>
     <NaviBar />
     <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/price" element={<Price />}></Route>
      <Route path="/sample" element={<Sample />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/faq" element={<FAQ />}></Route>
      <Route path="/mini" element={<EventPage />}></Route>
      <Route path="/blog" element={<Blog />}></Route>
      <Route path="/template/restaurant" element={<Restaurant />}></Route>

      </Routes>
      <Ad />
      <Event />
      <Biz />
      <Footer />



    </div>
  );
}

export default App;
