function FaqPanel (props) {
    return (
        <div>
            <div className="w-4/5 mx-auto py-5">
                <h2 className="font-bold pb-2">Q{props.title}?</h2>
                <div>
                    <p>A.{props.text}</p>
                </div>
            </div>

        </div>
    );
}

export default FaqPanel;