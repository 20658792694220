import OrangeButton from "./Parts/OrangeButton";
import WhiteButton from "./Parts/WhiteButton";


function Ad () {

    const orange =[
        {
            link:'https://forms.gle/hczn6bu6nkeDYSkw9',
            text:'今すぐ申し込む'
        },
        {
            link:'https://cachitto-page.com/price',
            text:'シンプルな料金プランはこちら'

        }

    ]

    const white = [
        {
            link:'https://cachitto-page.com/sample',
            text:'これまでの実績はこちら'

        },
        {
            link:'https://cachitto-page.com/contact',
            text:'お問合せはこちら'

        }

    ]
    return (
        <div className="bg-LightGray py-5">
            <div className="w-11/12 m-auto pt-20 ">
            <img className="opacity-40 w-1/2 md:w-1/4 m-auto" src="./assets/logo.png" alt="" />
               <h3 className="relative -top-48 text-center leading-loose">シンプルでカチッとしたHP制作なら<br />カチッとページまで<br />月額¥5,500~から制作、運用いたします。</h3>
            </div>

            <div className="text-center">
              {orange && orange.map ((content)=> (
                     <OrangeButton
                         link={content.link}
                         text={content.text}
                         />

                     ))}
            </div>

            <div className="text-center">
              {white && white.map ((content)=> (
                     <WhiteButton
                         link={content.link}
                         text={content.text}
                         />

                     ))}
            </div>

        </div>
    ); 
}

export default Ad ;