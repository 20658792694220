function Points (props) {
    return (
        <main className="">
            <img className="w-1/2 md:w-1/4 m-auto" src={"./assets/"+ props.img} alt="" />
            <h3 className="font-bold text-l text-center">{props.title}</h3>
            <p className="py-5 w-4/5 md:w-1/2 m-auto leading-relaxed">{props.text}</p>
           
        </main>
    );
}

export default Points;