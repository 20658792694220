
function SamplePanel(props) {
    return (
        <div className="">
            <a className=" " target="_blank " rel="noopener" href={props.link}><img className="w-4/5  m-auto py-3 break-inside-avoid" src={"./assets/"+props.twittercard} alt="" /></a>
            
        </div>
    );
}

export default SamplePanel;