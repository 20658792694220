import { Link } from "react-router-dom";

function NaviLinks (props) {
    return (
        <ul className="md:w-2/3 mx-auto py-5 md:flex md:py-0  md:my-auto md:justify-between leading-loose  ">
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold"><Link to="/">Home</Link></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold"><Link to="/price">料金</Link></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold"><Link to="/sample">制作実績</Link></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold"><Link to="/contact">お問合せ</Link></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold"><Link to="/faq">よくある質問</Link></li>
          <li onClick={() => props.isMobile && props.closeMobileMenu()} className="md:hover:text-ashellRed font-bold"><Link to="/mini">カチッとmini</Link></li>
        </ul>
    );
}

export default NaviLinks;