import NaviLinks from "./NaviLinks";

function Footer () {
    return (
        <footer className="bg-gray-700 py-3 ">
            <div className="w-11/12 m-auto md:flex">
             <div className="md:pl-6">
               <img className="brightness-0 invert w-1/6 m-auto md:w-1/6 md:m-0" src="./assets/logo.png" alt="" />
             </div>

            <div className="text-center w-full my-auto text-white">
            <NaviLinks />
             <div className="w-4/5 m-auto font-bold leading-loose md:flex justify-around">
               <a href="https://instagram.com/cachitto_page">Instagram</a>
               <br className="block md:hidden " />
               <a className="" href="https://twitter.com/cachitto_page">Twitter</a>
               <br className="block md:hidden " />
               <a href="https://oratos97.com">運用者</a>
             </div>
            </div>


            </div>

            <p className="w-4/5 m-auto hidden text-white text-center md:text-left py-4">シンプルでカチッとしたHP制作ならカチッとページまで</p>

            
           
        </footer>
    );
}

export default Footer;