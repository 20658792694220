
function PriceListPanel (props) {
    return (
        <div className="w-4/5 m-auto text-lg py-5 md:py-5 text-center">
            
            
            <div className="text-6xl text-BrandOrange">{props.icon}</div>
            <h3 className="text-center font-bold">{props.title}</h3>
            <p>{props.text}</p>
            <small>{props.attention}</small>

        </div>
    );
}

export default PriceListPanel;