import MainPanel from "../Components/Parts/MainPanel.js";
import Points from "../Components/Parts/PointsPanel.js";


function Home () {
    const top = [
        {
            title:"シンプルでカチッとしたHP制作サービスならカチッとページ",
            text:'ビジネス導入期から安定した利益を残したい全ての方へ。シンプルでカチッとしたHPを素早くリーズナブルに制作します！',
            img:'analytics.png'
        },

  
    ]

    const points = [
      {
          title:"必要最低限の機能を用意し価格を抑えます！",
          text:'派手なアニメーションは使用しません。既存SNSやGoogleが提供しているサービスなどで代替可能な機能はそちらで対応します。そうすることで開発費用を抑えリーズナブルなHP運用を実現します。',
          img:'coding.png'
      },
      {
        title:"抜群の配色でシンプルでスタイリッシュなHP！",
        text:'お客様のお持ちの素晴らしい素材やスタイリッシュなフリー素材などを活用しシンプルながらもおしゃれなHPを制作いたします。',
        img:'design.png'
    },

    {
      title:"HP公開後も万全のサポート！",
      text:'HPの公開後も微細な修正は月額費用内で行います。HPは公開してからが本番です。お気軽に修正依頼などもご活用ください。',
      img:'analytics.png'
  },



    ]


    return (      
          <div className=''>
            {top && top.map ((content)=> (
            <MainPanel
                 title={content.title}
                 text={content.text}
                 img={content.img}
            
            
            />

            ))}

          <div className='bg-LightGray'>
                 <h2 className='w-4/5 m-auto text-center py-3 font-bold'>こんなHP制作サービスを<span className="inline-block">見たことはありませんか？</span></h2>
                 <img className='w-1/2 md:w-1/3 m-auto' src="./assets/developer.png" alt="" />

             <div className='w-11/12 m-auto text-center'>
                <p className='py-2'>1 デザインは良いけどアニメーションや<span className='inline-block'>不要な機能が多すぎて高額</span> </p>
                <p className='py-2'>2 安価なサービスを選ぶとデザインが微妙...</p>
                <p className='py-2'>3 HPが完成したのは良いけど、<span className='inline-block'>そのあとのサポートがさっぱり...</span></p>
            </div>

             </div>


        <div className="bg-gray-100">
        <div>
            <h2 className='text-center font-bold text-xl py-5'>カチッとページなら</h2>
        </div>

        {points && points.map ((content)=> (
            <Points
                 title={content.title}
                 text={content.text}
                 img={content.img}
            
            
            />

            ))}


        </div>
       
       



          </div>
          
        
    );
}

export default Home;