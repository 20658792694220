import {FaYenSign} from "react-icons/fa";

function PriceOptionPanel (props) {
    return (
        <ul className="w-4/5 md:w-1/3 m-auto text-lg py-5">
            
            
            <div className="text-6xl text-BrandOrange">{props.icon}</div>
            <h3 className="text-center font-bold">{props.title}</h3>
            <p>料金 : {props.price}(税込)/月</p>
            <p>{props.limit1}</p>
            <p>{props.limit2}</p>
            <p>{props.limit3}</p>
            <small>{props.attention}</small>
        </ul>
    );
}

export default PriceOptionPanel;